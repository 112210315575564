import { useQueries, useQuery } from '@tanstack/vue-query'

import { type User } from './user'

export interface UserPermissions extends UserPermissionsCanView {
  id: number // integer // readOnly
  is_manager: boolean
  is_admin: boolean // readOnly
}
interface UserPermissionsCanView {
  can_view_contact: boolean
  can_view_contract: boolean
  can_view_download: boolean
  can_view_power: boolean
  can_view_ticket: boolean
  can_view_traffic: boolean
}
export type UserPermissionName = keyof UserPermissionsCanView

interface UserPermissionsList {
  count: number // eg. 123,
  next: string // eg. "http://api.example.org/accounts/?offset=400&limit=100",
  previous: string // eg. "http://api.example.org/accounts/?offset=200&limit=100",
  results: UserPermissions[]
}
/**
 * for: manager, admin
 * https://kupo-test.ibh.de/rest/schema/swagger-ui#/permission/permission_list
 */
export function usePermissionsList(offset: number, limit: number) {
  return useQuery({
    queryKey: ['get', 'permission', { offset, limit }],
    queryFn: () =>
      useApi().get(`permission`, { searchParams: { offset, limit } }).json<UserPermissionsList>(),
  })
}

/**
 * for: any user
 * https://kupo-test.ibh.de/rest/schema/swagger-ui#/permission/permission_own_retrieve
 */
export function useOwnPermissions() {
  return useQuery({
    queryKey: ['get', 'permission', 'own'],
    queryFn: () => useApi().get(`permission/own`).json<UserPermissions>(),
    refetchInterval: 5 * 60 * 1000,
    retry: false,
  })
}

/**
 * for: admin, manager (only for users below their tenancy),
 * rules: is_manager user cannot give themselves other permissions; is_manager can only be set by user with is_admin permission; is_admin cannot be set via API
 * https://kupo-test.ibh.de/rest/schema/swagger-ui#/permission/permission_retrieve
 */
function retrieveUserPermission(userId: User['id']) {
  return useApi().get(`permission/${userId}`).json<UserPermissions>()
}

export const queryKey_get_permission = (userId: User['id']) => ['get', 'permission', { userId }]

/**
 * for: admin, manager (only for users below their tenancy),
 * rules: is_manager user cannot give themselves other permissions; is_manager can only be set by user with is_admin permission; is_admin cannot be set via API
 * https://kupo-test.ibh.de/rest/schema/swagger-ui#/permission/permission_retrieve
 */
export function useUserPermissions(userIds: MaybeRefOrGetter<User['id'][]>) {
  const queries = computed(() =>
    toValue(userIds).map((userId) => ({
      queryKey: queryKey_get_permission(userId),
      queryFn: () => retrieveUserPermission(userId),
    })),
  )
  return useQueries({ queries })
}

interface UpdateUserPermissionsVariables {
  userId: User['id']
  permissionsUpdate: Partial<UserPermissionsCanView>
}
/**
 * for: admin, manager (only for users below their tenancy)
 * https://kupo-test.ibh.de/rest/schema/swagger-ui#/permission/permission_partial_update
 */
export function useUpdateUserPermissions() {
  return useOptimisticMutation({
    mutationFn: ({ userId, permissionsUpdate }: UpdateUserPermissionsVariables) =>
      useApi().patch(`permission/${userId}`, { json: permissionsUpdate }).json<UserPermissions>(),
    optimisticUpdates: ({ userId, permissionsUpdate }: UpdateUserPermissionsVariables) => [
      {
        queryKey: queryKey_get_permission(userId),
        updater: (previousPermissions: UserPermissions | undefined) => ({
          id: 0,
          is_manager: false,
          is_admin: false,
          can_view_contact: false,
          can_view_contract: false,
          can_view_download: false,
          can_view_power: false,
          can_view_ticket: false,
          can_view_traffic: false,
          ...previousPermissions,
          ...permissionsUpdate,
        }),
      },
    ],
    otherAffectedQueries: (variables) => [
      { queryKey: ['get', 'permission'], options: { exact: true } },
      // update own permissions
      {
        queryKey: ['get', 'permission', 'own'],
        options: {
          predicate: (_) => {
            const auth = useAuth()

            return variables.userId === auth.ownPermissions?.id
          },
        },
      },
    ],
  })
}
